<template>
  <div>
    <ServerError v-if="ServerError" />
    <PageLoader :storage="appLoading" />
    <v-snackbar
      v-model="showSnackBar"
      :color="appColor"
      right
      :timeout="timeout"
    >
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs12>
        <v-card
          @click="assignDialogue1 = true"
          elevation="2"
          :ripple="false"
          style="cursor: pointer"
          tile
          class="pa-2"
        >
          <span
            style="font-family: sofiaProSemiBold; color: #000; font-size: 14px"
          >
            Assign To
          </span>
        </v-card>
        <v-dialog
          v-model="assignDialogue1"
          p
          :width="
            $vuetify.breakpoint.name == 'xs' ||
            $vuetify.breakpoint.name == 'sm' ||
            $vuetify.breakpoint.name == 'md'
              ? '100vw'
              : $vuetify.breakpoint.name == 'lg'
              ? '50vw'
              : '50vw'
          "
        >
          <v-card elevation="2" tile>
            <v-layout pa-4 wrap justify-center pa-8>
              <v-flex xs12>
                <span style="font-size: 24px" class="itemSubHeading">
                  Assign To</span
                >
              </v-flex>
              <v-flex xs12>
                <span style="font-size: 13px" class="smallLightHeading">
                  Select Officials Near by the Conflict area.</span
                >
              </v-flex>
              <v-flex xs12 pt-2>
                <v-layout wrap justify-start>
                  <v-flex xs12>
                    <v-select
                    outlined
                    dense
                    hide-details="auto"
                    :items="officiallist"
                    v-model="officialname"
                    class="itemform"
                    label="Assign To">
                    </v-select>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 pt-4 text-center>
                <v-layout wrap justify-center v-if="officialname">
                  <v-flex xs12 sm3 md3 lg3 class="smallLightHeading" v-if="officialname && (officialname === 'RRT' || officialname === 'RANGE')">
                    <v-autocomplete
                      outlined
                      label="Select Circle"
                      :items="circleList"
                      v-model="circlename"
                      item-text="circleName"
                      item-value="_id"
                      class="itemform"
                      dense
                    >
                    </v-autocomplete>
                  </v-flex>
                  <v-flex xs12 sm3 md3 lg3 pl-md-2
                    pl-lg-2
                    pl-sm-2 class="smallLightHeading" v-if="officialname && (officialname === 'RRT' || officialname === 'RANGE')">
                    <v-autocomplete
                      outlined
                      label="Select Division"
                      :items="divisionList"
                      v-model="divisionname"
                      item-text="divisionName"
                      item-value="_id"
                      class="itemform"
                      :disabled="circlename ? false : true"
                      dense
                    >
                    </v-autocomplete>
                  </v-flex>
                  <v-flex
                    xs12
                    sm3
                    md3
                    lg3
                    pl-md-2
                    pl-lg-2
                    pl-sm-2
                    class="smallLightHeading"
                    v-if="officialname && (officialname === 'RRT' || officialname === 'RANGE')"
                  >
                    <v-autocomplete
                      outlined
                      label="Select Range"
                      :items="rangeList"
                      v-model="rangename"
                      item-text="rangeName"
                      item-value="_id"
                      class="itemform"
                      dense
                      :disabled="divisionname ? false : true"
                    >
                    </v-autocomplete>
                  </v-flex>
                  <v-flex xs12 sm3 md3 lg3 pl-md-2 pl-lg-2 pl-sm-2  v-if="officialname && officialname === 'RRT'">
                    <v-autocomplete
                      outlined
                      label="Select RRT"
                      :items="rrtList"
                      v-model="rrtname"
                      item-text="name"
                      item-value="_id"
                      class="itemform"
                      :disabled="rangename ? false : true"
                      dense
                    >
                    </v-autocomplete>
                  </v-flex>
                </v-layout>
                <v-layout justify-center>
                  <v-flex xs12 lg4 pt-1 pl-lg-2 v-if="officialname">
                    <v-btn
                      block
                      small
                      :color="appColor"
                      dark
                      :ripple="false"
                      depressed
                      type="submit"
                      @click="assignToRrt()"
                      :disabled="submitrue"
                    >
                      <span class="text-capitalize" style=""> Assign </span>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card>
        </v-dialog>
      </v-flex>
    </v-layout>
  </div>
</template>
    <script>
import axios from "axios";
export default {
  props: ["caseDetails", "createDate"],
  data() {
    return {
      assignDialogue1: false,
      assignDialogue2: false,
      msg: null,
      showSnackBar: false,
      timeout: 5000,
      appLoading: false,
      ServerError: false,
      officiallist: [
        "RANGE",
        "RRT",
      ],
      assigningItem: {},
      divisionname: "",
      divisionList: [],
      rangeList: [],
      officialname:"",
      circleList: [],
      circlename:"",
      rangename: "",
      rrtname: "",
      rrtList: [],
      submitrue: false,
    };
  },
  beforeMount() {
    this.getCircleData();
  },
  watch: {
    circlename() {
      this.getDivisionData();
      this.divisionname = "";
      this.rangename = "";
      this.rrtname = "";
    },
    officialname() {
      this.circlename = "";
      this.divisionname = "";
      this.rangename = "";
      this.rrtname = "";
    },
    divisionname() {
      this.getRangeData();
      this.rangename = "";
      this.rrtname = "";
    },
    rangename() {
      this.getRRTData();
      this.rrtname = "";
    },
  },
  methods: {
    assign(assignType, officerId) {
      this.assignDialogue1 = false;
      this.assignDialogue2 = false;
      this.$emit("stepper", {
        type: "assignFunction",
        assignType: assignType,
        officerId: officerId,
      });
    },
    determineRole() {
      if (this.rrtname) return 'RRT';
      if (this.rangename) return 'RANGE';
      if (this.divisionname) return 'DIVISION';
      return null;
    },
    assignToRrt() {
      //   const role = this.determineRole();
      //   if (!this.divisionname) {
      //   this.msg = "Please Select A Division.";
      //   this.showSnackBar = true;
      //   return;
      // }
      // const requestData = {
      //   reportId: this.caseDetails._id,
      //   divisionId: this.divisionname,
      //   role: role
      // };
      // if (this.rangename) {
      //   requestData.rangeId = this.rangename;
      // }
      // if (this.rrtname) {
      //   requestData.userId = this.rrtname;
      // }
      if ((this.officialname === "RRT" || this.officialname === "Range") && !this.circlename) {
        this.msg = "Please Select A Circle.";
        this.showSnackBar = true;
        return;
      }
      if ((this.officialname === "RRT" || this.officialname === "Range") && !this.divisionname) {
        this.msg = "Please Select A Division.";
        this.showSnackBar = true;
        return;
      }
      if ((this.officialname === "RRT" || this.officialname === "Range") && !this.rangename) {
        this.msg = "Please Select A Range.";
        this.showSnackBar = true;
        return;
      }
      if (this.officialname === "RRT"  && !this.rrtname) {
        this.msg = "Please Select An RRT.";
        this.showSnackBar = true;
        return;
      }
      this.appLoading = true;
      this.submitrue = true;
      axios({
        method: "POST",
        url: "/superadmin/report/assignto",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          reportId: this.caseDetails._id,
          circleId: this.circlename,
          divisionId: this.divisionname,
          rangeId: this.rangename,
          userId: this.rrtname,
          role: this.officialname,
        },
        // data: requestData,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.assignDialogue1 = false;
            this.$router
              .push({
                path: "/admin/adminDashboard?id=assignedCases",
              })
              .then(() => {
                window.location.reload();
              });
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.submitrue = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getDivisionData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/platform/division/getlistdropdown",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          circleId: this.circlename,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.divisionList = response.data.data;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getCircleData() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/platform/circle/getlistdropdown",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.circleList = response.data.data;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getRangeData() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/platform/range/getlistdropdown",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          division: this.divisionname,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.rangeList = response.data.data;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getRRTData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/rescuer/rrt/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          division: this.divisionname,
          range: this.rangename,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.rrtList = response.data.data;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>